@import url('../SwithCurriculum/styles.css');

.attachment-uploader {
  width: 100%;
}
.add-question-container {
  height: inherit;
  overflow: auto;
}
.edit-question-container {
  height: 85vh;
  overflow: auto;
}
.mass-publish {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.add-question-card {
  height: inherit;
  height: 100%;
  overflow: auto;
  margin: auto;
  border-radius: 5px;
  padding: 10px;
}
.option-addons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question-addons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.tag-addons {
  cursor: pointer;
  max-width: 200px;
  overflow: auto;
}
.back-link {
  color: #1890ff;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
.question-footer {
  margin-top: 10px;
  border-radius: 5px;
}
.file-upload-btn {
  color: #2499c6
}

.option-card {
  border: 1px solid #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}
.question-body {
  width: 100%;
}
.question-content {
  width: 100%;
}

.preview-question {
  overflow: auto;
   height: 100%;
}
.card-footer-button {
  display: flex;
  justify-content: center;
}
.preview-question-card {
  height: 100%;
}
.comprehension-header {
 font-weight: 700;
 padding-bottom: 10px;
 border-bottom: 1px solid #ccc
}
.comprehensions {
  padding-bottom: 10px;
 border-bottom: 1px solid #f0f0f0
}