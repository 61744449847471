@import '../styles/content.styles.scss';
.tag {
  background-color: $blue;
  padding: 2px;
  border-radius: 3px;
  font-size: 80%;
  margin-left: 10px;
}
.content-master {
  background-color: $white;
  height: calc(100vh - 70px);
  width: 100%;
  padding: 10px;

  &__title {
    cursor: pointer;
    font-size: 120%;
    font-weight: 400;

    &:hover {
      color: $blue
    }
  }
  &__topic-list{
    height: calc(100vh - 180px);
    overflow: hidden;
  }
  &__unit {

    &--title {
      width: 100%;
      margin: 20px 0;
      text-transform: uppercase;
      font-weight: 400;
    }
    &--content {
      width: 100%;
      margin-top: 10px;
    }
  }
  &__topic {
    &--content {
      width: 100%;
      margin-top: 10px;
      font-size: 105%;
      // padding: 5px 0px;
    }
    &--name {
      display: flex;
      align-items: center;
    }
  }
  &__subtopic {
    &--content {
      width: 100%;
      padding: 5px 0px;
      margin-right: 20px;
    }
  }
  
  &__header-section {
    display: flex;
    align-items: center;
    padding: 15px 0;
    width: 100%;

    &--title-section{
      flex: 1;
      display: flex;
    }
    &--title {
      margin-right: 20px;
      font-size: 120%;
    }
   
    &--search {
      display: flex;
      align-items: center;
      min-width: 400px;
      justify-content: flex-end;
    }
  }

}
.unit-title {
  cursor: pointer;
}
.unit-filter-nav {
  display: flex;
  width: 100%;
  align-items: baseline;

  .filter-field {
    width: 300px;
    margin: 0 10px;
  }
  .clear-filters {
    margin-left: 10px;
    flex: 1
  }
}

.topic-list {
  height: inherit;
  overflow: auto;
}

.selected-course {
  background-color: $light-blue;
  padding: 3px;
  border-radius: 5px;
  color: $blue;
  margin-left: 10px;
}
.switch-course {
  width: 100%;
  display: flex;
  align-items: center;

  &__modal-btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    
  }
  
}
.modal-close {
  margin-right: 10px ;
}

.sub-topic-list {
  overflow: auto;
  height: 85%;
}