@import '../styles/content.styles.scss';
@import '../Master/styles.scss';
@import url('../Questions/questions.styles.scss');

.quiz-content {
  background-color: $white;
  padding: 10px;
  overflow: hidden;
  height: inherit;
}
.quiz-content-header-section {
  height: auto;
  margin-bottom: 10px;
}
.quiz-list {
  overflow: auto;
  height: calc(100% - 260px);
}
.quiz-item {
  align-items: center;
  font-size: 105%;
}
.bold {
  font-weight: 400;
}
.question-item {
  padding: 10px 0;
}
.option-item {
  padding: 10px 0;
}
.answer-icon {
  color: $light-green;
}
.span {
  margin-right: 2px;
}
.no-questions {
  padding: 10px 0;
  color: $orange;
  margin-left: 20px;
}
.quiz-item-header {
  font-weight: 700;
}
.attach-select {
  min-width: 100%;
}
.attach-item {
  align-items: center;
  padding: 10px 0;
  background-color: #f9f9f9;
  margin: 8px 0;
}
.attach-btn {
  margin-top: 15px;
  padding: 9px;
}
.not-found-container {
  width: 500px;
  margin: auto;
}
.topic-name {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.selected,
.selected:active,
.selected:focus {
  font-weight: 800;
}
.attach-topic-search {
  display: flex;
  justify-content: flex-end;
}
.course-tag {
  background-color: $blue;
}
.quiz-filter-field {
  width: 200px;
  margin-right: 10px;
}
.attach-quiz-tag {
  min-width: 100px;
}
.invalid {
  color: #ff0000;
}
.invalid-input {
  border-color: #ff0000 !important;
}
.lesson {
  text-align: justify;
}
.topic-item {
  padding: 0 10px;
  word-wrap: break-word;
  font-size: 90%;
}

.lesson-section {
  align-items: center;
}
.action-wrapper {
  display: flex;
  justify-content: space-between;
}
.update-lesson,
.update-option,
.update-quiz,
.update-question {
  display: flex;
  justify-content: flex-end;
}
.view-questions,
.view-options {
  display: flex;
  justify-content: flex-end;
}
.delete-icon {
  color: $orange;
  display: flex;
  align-items: center;
}
.option-label {
  display: flex;
  font-weight: 500;
  align-items: center;
}
.is-correct {
  color: $light-green;
  margin-left: 2px;
}
.total {
  font-weight: 400;
  color: $blue;
}
.quiz-settings-btn {
  display: flex;
  justify-content: flex-end;
}
.actions-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.draggable-card {
  border: 1px solid #f9f9f9;
  cursor: pointer;
}
.draggable-icon {
  cursor: move;
}
