@import '../../../sass/variables';

.content {
  width: 100%;
  height: 100%;
  padding: 5px;
  // overflow: scroll;

  &__table-header {
    background-color: $white;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    margin-right: 20px;
  }
  &__title-container {
    display: flex;
    align-items: center;
  }
  &__search {
    width: 300px;
    border-radius: 5px;
  }
  &__menu {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 90%;
    p {
      cursor: pointer;
    }
  }
  &__filter-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }
  &__filter {
    display: flex;
    justify-content: space-between;

    .select {
      width: 150px;
      margin-right: 5px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  &__header {
    margin: 0 0 20px 0;
  }
  &__header-title,
  &__header-search {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::first-child {
      margin-right: 15px;
    }
  }
  &__btn {
    width: 100px;
    background-color: $blue;
    border: none;
    &:focus {
      outline: none;
    }
  }
  &__actions {
    display: block;
  }

  &__icon {
    p {
      margin: 0;
      padding-right: 5px;
    }
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    &--delete {
      color: $orange;
    }
    &--edit {
      color: $green;
    }
    &--add {
      color: $blue;
    }
    &--view {
      color: $dark-blue;
    }
    &--preview {
      color: $blue;
    }
    &--publish {
      color: $blue;
    }
  }
  &__button-container {
    background-color: $white;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
  }

  .table {
    border: 1px solid $light-grey;
    height: auto;
    &__row {
      background-color: $light-grey;
    }
  }

  .card-item {
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px;
    overflow: scroll;

    .link {
      color: $blue;
      cursor: pointer;
    }
  }
  .content-form {
    display: flex;
    justify-content: center;
  }
}
.add-quiz-container {
  background-color: $white;
  width: 100%;
  padding: 5px;
  margin: 5;
  height: 100%;
  overflow: auto;

  &__row {
    padding: 10px;
  }
  &__header {
    display: flex;
    padding: 0 5px;
  }

  &__breadcrumbs {
    margin-right: 20px;
  }

  .button-container {
    padding: 10px
  }

  .quiz-settings {
    border: 1px solid $light-grey;
    padding: 10px;
  }
}
.collapse,
.show {
  margin: 0;
  padding: 0;
}

.quizzes-table-header { 
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 90%;
}


@media screen and (max-width: 768px) {
  .content {
    &__search {
      width: 100%;
    }
    .quiz {
      &__title-container,
      &__filter-nav {
        display: none;
      }
      &__search {
        width: 200px;
      }
    }
  }
}
