@import '../styles/content.styles.scss';

.table-container {
  background-color: $white;
  height: 100%;
  overflow: auto;
}
.add-question-button {
  margin-left: 15px;
  margin-top: 10px;
}
.question-body {
  align-items: flex-start;

  &__label {
    display: flex;
    justify-content: space-between;
  }
}
.question-delete {
  cursor: pointer;
  color: $orange;
  display: flex;
  align-items: center;
  // font-size: 120%;
}
.add-question-card {
  background-color: $white;
  padding: 10px 0;
  width: 100%;
  margin: 5px 0
}
.link {
  color: $blue;
  cursor: pointer;
}
.question-type-container {
  border: 1px solid $light-grey;
  padding: 10px;
}
.answer-icon {
  color: $light-green;
}