@import './sass/variables';

html {
  font-size: 14px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

.form-control:focus {
  border-color: $blue;
  box-shadow: 0 0 2px $blue;
  outline: none;
}
.form-control {
  height: 38px;
}

.wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
}
.main-content {
  margin-top: 60px;
  padding: 10px;
  width: calc(100% - 256px);
  // height: calc(100% - 60px);
  overflow: hidden;
  background-color: $light-grey;
  transition: all 0.3s ease-out;

  &.open {
    width: calc(100% - 256px);
  }
  &.closed {
    width: 100%;
  }
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 150%;
}
.action-menu {
  background-color: $white;
}
.action-menu-items {
  text-decoration: none;
  border: none;
  &:focus,
  &:active {
    background-color: $light-grey;
    border: none;
  }
}
.vertical-button {
  font-size: 170%;
  color: $blue;
}

.btn {
  height: 38px;
}

.btn-primary {
  color: $white;
  border: none;
  background-color: $blue;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: $white;
    background-color: $blue;
    box-shadow: none;
  }
  &:disabled {
    text-decoration: none;
    outline: none;
    color: $white;
    background-color: $blue;
    box-shadow: none;
    cursor: not-allowed;
  }
}
.btn-link {
  border: none;
  color: $blue;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: $blue;
    box-shadow: none;
  }
}
a {
  border: none;
  color: $blue;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: $blue;
    box-shadow: none;
  }
  padding: 0 5px;
}
.btn-secondary {
  color: $blue;
  border: 1px solid $blue;
  background-color: $white;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: $blue;
    border-color: $blue;
    background-color: $white;
  }
}
.btn-add {
  color: $orange;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }
}
.cms-btn {
  margin: 5px;
}

.tooltip-button {
  color: $light-green;
  font-size: 130%;
}
.settings-title {
  text-transform: uppercase;
}
.search-field {
  min-width: 300px;
}
.toggle-button,
.MuiSwitch-root,
.MuiSwitch-colorSecondary.Mui-checked {
  color: $blue !important;
}
.editor {
  font-size: 100% !important;
}
.mce-content-body {
  body {
    background-color: #fff;
    color: #000000ad;
    font-family: 'Work Sans' !important;
    font-size: 100% !important;
  }
}
.icons-container {
  p {
    margin: 0;
    padding-right: 5px;
  }
  display: none;
  margin-top: 5px;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
  &__delete {
    color: $orange;
  }
  &__edit {
    color: $green;
  }
  &__reset {
    color: $blue;
  }
  &__add {
    color: $blue;
  }
  &__view {
    color: $dark-blue;
  }
}
.select-input-100 {
  min-width: 100% !important;
}
.lesson {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 0.8;
}
@media screen and (max-width: 768px) {
  .main-content {
    &.open {
      width: 100%;
    }
  }
}

input {
  font-size: 90%;
  font-weight: 300;
}
label {
  font-weight: 400;
}

.tag-wrapper {
  background-color: $blue;
  color: $white;
  padding: 2px;
  font-size: 80%;
  border-radius: 4px;
  max-width: 10px;
  overflow: auto;
  margin: 2px;
  cursor: pointer;
}
.selected {
  border-bottom: 1px solid;
  border-color: $blue;
}
.input-spinner {
  background-color: $white;
  border-left: none;
}
.action-btn {
  margin-top: -5px;
  position: absolute;
  right: 0;
}
.cb-tabs {
  display: flex;
  align-items: center;
}
.selected-status {
  border-bottom: 1px solid #1890ff;
}
.comment-wrapper {
  padding: 5px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  border-radius: 4px;
}
.paginate {
  display: flex;
  justify-content: flex-end;
}
