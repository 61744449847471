/* colors */
$white: #ffffff;
$light-grey: #e9ecef;
$light-grey-1: #f9f9f9;
$grey: #6c757d;
$blue: #2499c6;
$light-blue: #ddf0f7;
$dark-blue: rgb(19, 77, 92);
$green: #3f973c;
$light-green: #1ada14;
$orange: #d15c0e;
$red: #f01414;
$black: rgb(11, 12, 11);